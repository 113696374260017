$addBtn-width: 30px;


.mdSideToolbar {
  display: flex;
  position: absolute;
  z-index: 1;
  left: -35px;
  top: 0;
  transition: all 0.2s ease;
}

.mdSBButton {
  background: none;
  border: 1px solid #5b5b5b;
  color: #6D6D6D;
  cursor: pointer;
  height: $addBtn-width;
  width: $addBtn-width;
  border-radius: $addBtn-width/2;
  font-weight: bold;
  font-size: 20px;
  margin-right: 2px;
  textAlign: center;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #08c;
    color: #08c;
  }

  &:hover {
    color: green;
  }

  &.mdSBImgButton {
    background: none;
    background-color: white;
  }
  i {
      margin:4px 0;
      font-size:17px;
  }
  svg path {
    stroke-width: 0;
    fill: #545454;
  }
}

.mdAddButton {
  &.mdOpenButton {
    transform: rotate(45deg);
  }
}

.mdAddBtnAnimAppear {
  transform: translate3d(-38px, 0px, 0px);
  opacity: 0;
}

.mdAddBtnAnimAppear.mdAddBtnAnimAppearActive {
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  transition: all .5s ease-in;
}

.mdAddBtnAnimEnter {
  transform: translate3d(-38px, 0px, 0px);
  opacity: 0;
}

.mdAddBtnAnimEnter.mdAddBtnAnimEnterActive {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  transition: all 500ms ease-in;
}

.mdAddBtnAnimExit {
  opacity: 1;
}

.mdAddBtnAnimExit.mdAddBtnAnimExitActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
