html,
body {
    font-family: az_ea_font,'Segoe UI',az_font,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    /*overflow: hidden;*/
}

a {
    color: inherit;
    text-decoration: none;
}

.fxs-hidefocusoutline * {
    outline: none !important;
}

a {
    color: #0078d4;
}

a {
    text-decoration: none;
}

svg {
    overflow: hidden;
}

[role="button"], [role="link"], .fxs-fxclick {
    cursor: pointer;
}

img, a img, :link img, :visited img {
    border: 0;
}

img {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
}

a, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, meter, nav, object, ol, optgroup, option, output, p, pre, progress, q, section, small, span, strong, sub, summary, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, u, ul, var, video {
    border: 0;
    margin: 0;
    padding: 0;
}
#web-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: normal;
}
